<template>
  <div class="comment-form">
    <el-avatar :size="50" :src="commentInfo.avatar" class="comment-avatar"></el-avatar>
    <div :class="{'comment-form-wrap': !isSmallTextarea, 'comment-form-wrap-small': isSmallTextarea}">
      <!--输入框-->
      <div class="comment-input-wrap">
        <el-form ref="commentInfoLess" :model="commentInfo" :rules="formRules" inline>
          <el-form-item prop="qq">
            <el-tooltip content="自动拉取昵称头像 可神奇啦" effect="dark" placement="top">
              <!--根评论用大号样式，子评论用小号样式-->
              <el-input v-model="commentInfo.qq"
                        :class="{'comment-input': !isSmallInput, 'comment-input-small': isSmallInput}" placeholder="必填"
                        @input="getQQInfo">
                <template slot="prepend">QQ</template>
              </el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item prop="email">
            <el-tooltip content="被回复时将收到提醒" effect="dark" placement="top">
              <el-input v-model="commentInfo.email"
                        :class="{'comment-input': !isSmallInput, 'comment-input-small': isSmallInput}" placeholder="选填">
                <template slot="prepend">邮箱</template>
              </el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item prop="website">
            <el-tooltip content="can can need blog 🤤" effect="dark" placement="top">
              <el-input v-model="commentInfo.website"
                        :class="{'comment-input': !isSmallInput, 'comment-input-small': isSmallInput}" placeholder="选填">
                <template slot="prepend">网站</template>
              </el-input>
            </el-tooltip>
          </el-form-item>
        </el-form>
      </div>
      <el-input type="textarea" :rows="5" v-model="commentInfo.content" placeholder="留下你的足迹吧 (ゝ∀･)"
                maxlength="250" show-word-limit :validate-event="false"></el-input>
      <div class="comment-meta">
        <!--表情框,阻止mousedown的默认事件，防止文本域失去焦点，且点击时表情框没打开则使其获得焦点-->
        <div ref="emojiInput" class="emojiInput" @mousedown.prevent="getFocus">
          <!--打开表情框的图标-->
          <div class="emoji-open-button" @click="openEmoji">
            <i class="far fa-smile-wink"></i>
          </div>
          <div v-show="emojiShow" class="emoji-box">
            <!--标题-->
            <div class="emoji-title">
              <span>{{ emojiBoxName[emojiBoxIndex] }}</span>
            </div>
            <!--小电视-->
            <div v-show="emojiBoxIndex === 0" class="emoji-wrap">
              <div v-for="item in emojiMapper.tv" class="emoji-list">
                <img :src="item.src" :title="item.title" @click="insertEmoji(item.title)">
              </div>
            </div>
            <!--贴吧-->
            <div v-show="emojiBoxIndex === 1" class="emoji-wrap">
              <div v-for="item in emojiMapper.tieba" class="emoji-list">
                <img :src="item.src" :title="item.title" @click="insertEmoji(item.title)">
              </div>
            </div>
            <!--微博-->
            <div v-show="emojiBoxIndex === 2" class="emoji-wrap">
              <div v-for="item in emojiMapper.weibo" class="emoji-list">
                <img :src="item.src" :title="item.title" @click="insertEmoji(item.title)">
              </div>
            </div>
            <!--ac娘-->
            <div v-show="emojiBoxIndex === 3" class="emoji-wrap">
              <div v-for="item in emojiMapper.ac1" class="emoji-list">
                <img :src="item.src" :title="item.title" style="width: 49px;height: 49px;position: relative;bottom: 6px;" @click="insertEmoji(item.title)">
              </div>
            </div>

            <!--底部tab-->
            <div class="emoji-tabs">
              <a v-for="(val, index) in emojiBoxTabUrl" class="tab-link" @click="switchEmoji(index)">
                <img :src="val">
              </a>
            </div>
          </div>
        </div>

        <!--昵称栏-->
        <transition name="slide-fade">
          <div v-show="nicknameShow" class="comment_nickname" v-html="commentInfo.nickname"></div>
        </transition>
        <!--取消按钮，只在子评论上显示，使表单回到原本位置-->
        <button class="cancel-comment" name="submit" value="取消评论" v-if="pid !== -1" @click="$emit('cancelComment')">
          <i class="fas fas fa-ban"></i> 取消评论
        </button>
        <!--提交按钮-->
        <button class="submit-comment" name="submit" value="提交评论" v-throttle="[postComment,`click`,5000]">
          <i class="fas fa-paper-plane"></i> 提交评论
        </button>
      </div>
    </div>
  </div>

</template>

<script>
//导入表情包数据,在created时初赋值
import emojiMapper from "@/assets/emoji/emojiMapper.json"
export default {
  name: "commentForm",
  props: {
    page: null,
    blogId: null,
    pid: null,
    rootId: null,
    isSmallInput: null,
    isSmallTextarea: null,
    isSmallCancel: false,
    start: 0,
    end: 0,
  },
  data() {
    //表单校验规则，使用callback(new Error())可避免控制台打印输出，保证美观。必须返回callback()，否则无法判断valid的boolean
    const qqValidator = (rule, value, callback) => {
      let pattern = /^[0-9]*$/
      if (value.valueOf() < 10001 || value.valueOf() > 9999999999 || !pattern.test(value)) {
        return callback(new Error('请输入合法的QQ号'))
      }
      return callback()
    }
    const emailValidator = (rule, value, callback) => {
      let pattern = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
      if (!pattern.test(value) && value !== '') {
        return callback(new Error('请输入合法的邮箱'))
      }
      return callback()
    }
    const websiteValidator = (rule, value, callback) => {
      let pattern = /^https?:\/\/([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/
      if (!pattern.test(value) && value !== '') {
        return callback(new Error('请输入合法的网站'))
      }
      return callback()
    }
    return {
      formRules: {
        qq: [
          {require: true},
          {validator: qqValidator, trigger: 'blur'}
        ],
        email: [
          {validator: emailValidator, trigger: 'blur'}],
        website: [
          {validator: websiteValidator, trigger: 'blur'}
        ]
      },
      //isAdminComment属性在后端配合token验证进行设置
      commentInfo: {
        ip: '',
        //父组件的传值
        pid: this.pid,
        rootId: this.rootId,
        nickname: '',
        email: '',
        content: '',
        //默认头像
        avatar: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
        createTime: '',
        page: '',
        blogId: '-1',
        website: '',
        qq: '',
      },
      qqInfoOver: false,
      nicknameShow: false,
      emojiShow: false,
      emojiBoxIndex: 0,
      emojiBoxName: ["tv_小电机", "贴吧", "微博", "ac娘"],
      emojiBoxTabUrl: ["https://i0.hdslb.com/bfs/emote/c1d59f439e379ee50eef488bcb5e5378e5044ea4.png",
        "https://iceburst.top/img/tiebaEmoji/image_emoticon25.png",
        "https://face.t.sinajs.cn/t4/appstyle/expression/ext/normal/9c/2021_yingniunai_org.png",
        "https://tx-free-imgs2.acfun.cn/kimg/bs2/zt-image-host/ChYwOGZmOTRhYzRjMTBiNmQ4ZDdkNTAyEJjM1y8.png"],
      TimeId: "-1",
      emojiMapper: null,
      textarea: null
    }
  },
  created() {
    //读取表情包地址json
    this.emojiMapper = emojiMapper
    //初始化博客id(如果有)和页面类型,数据来自CommentList的created()
    this.commentInfo.blogId = this.blogId
    this.commentInfo.page = this.page
    //读取localStorage数据，注入表单，避免再次请求qq api
    if (window.localStorage.getItem("qq") != null
        && window.localStorage.getItem("avatar") != null
        && window.localStorage.getItem("nickname") != null){
      this.commentInfo.qq = window.localStorage.getItem("qq")
      this.commentInfo.avatar = window.localStorage.getItem("avatar")
      this.commentInfo.nickname = window.localStorage.getItem("nickname")
      this.nicknameShow = true
      this.qqInfoOver = true
    }
  },
  mounted() {
    this.textarea = document.querySelector(".el-textarea textarea")
    //点击其他区域，关闭当前表情框，不使用遮罩层
    document.addEventListener("click", this.closeEmoji)
  },
  methods: {
    getQQInfo() {
      //节流：0.7秒内无输入则执行请求，有输入则重置计时
      this.qqInfoOver = false
      clearTimeout(this.TimeId)
      this.TimeId = setTimeout(() => {
        // 请求第三方接口，会出现跨域，应在vue.config.js里设置代理，
        // 并在本次请求中置空baseURL（已在request中封装为http://${serverIp}:9090/）
        this.$request.get("https://api.usuuu.com/qq/" + this.commentInfo.qq, ).then(res => {
          //对于获取到的数据，url、name不为null且name不为空时才进行赋值，否则重置参数
          if (res.data.avatar != null && res.data.name != null && res.name !== "") {
            this.nicknameShow = true
            this.commentInfo.avatar = res.data.avatar
            this.commentInfo.nickname = res.data.name
            this.qqInfoOver = true
            window.localStorage.setItem("qq",this.commentInfo.qq)
            window.localStorage.setItem("avatar",this.commentInfo.avatar)
            window.localStorage.setItem("nickname",this.commentInfo.nickname)
          } else {
            this.nicknameShow = false
            this.commentInfo.avatar = "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
            //等待昵称div过渡动画结束再置null
            setTimeout(() => {
              this.commentInfo.nickname = null
            }, 500)
          }
        })
      }, 700)
    },
    openEmoji() {
      this.emojiShow = !this.emojiShow
    },
    closeEmoji(e){
      if (!this.$refs.emojiInput.contains(e.target)) {
        this.emojiShow = false
      }
    },
    switchEmoji(index) {
      this.emojiBoxIndex = index
    },
    //提交所有commentInfo信息
    postComment(isWindfury) {
      if (isWindfury === "Windfury"){
        return this.$notify({
          duration: 2000,
          title: "防止风怒机制",
          type: "warning"
        })
      }
      // 判断el-form commentInfo的item有无非法数据
      this.$refs["commentInfoLess"].validate((valid) => {
        //由于填入qq号700毫秒后才会请求头像昵称，若得到数据之前提交评论会导致数据缺失，故需在此判断
        if (!valid || this.commentInfo.content === "" || this.commentInfo.content.length > 250 || !this.qqInfoOver) {
          this.$notify({
            duration: 4500,
            title: '评论失败',
            message: '表单数据非法或用户信息未加载完成',
            type: 'error'
          })
        } else {
          //提交表单
          console.log(this.commentInfo)
          this.$request.post("comment/post-comment", this.commentInfo).then(res => {
            this.$notify({
              duration: 2000,
              title: '评论成功',
              type: 'success'
            })
          }).then(()=>{
            //刷新评论区
            this.$emit("refreshComments")
          })
        }
      })

    },
    getFocus() {
      if (!this.emojiShow) {
        this.textarea.focus()
      }
    },
    insertEmoji(title) {
      let str = this.commentInfo.content
      //
      this.start = this.textarea.selectionStart
      this.end = this.textarea.selectionEnd
      this.commentInfo.content = str.substring(0, this.start) + "[" + title + "]" + str.substring(this.end)
      this.start += title.length + 2
      this.end = this.start
      this.$nextTick(() => {
        this.textarea.setSelectionRange(this.start, this.end)
      })
    }
  },
  beforeDestroy(){
    //v-if销毁组件时解绑它的全部指令及事件监听器，但是仅限于组件本身的事件。
    //也就是说，在js内使用addEventListener等方式是不会自动销毁的，所以需要手动移除，不然控制台会疯狂报错
    document.removeEventListener("click", this.closeEmoji);
  }

}
</script>

<style scoped>
/deep/ .el-form--inline {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

}

/deep/ .el-form--inline .el-form-item {
  margin-right: 0px;
}

/deep/ .el-textarea__inner{
  padding: 5px 9px;
  width: 100%;
  font-size: 16px;
  font-family: MiSans-Medium, serif;
  line-height: 24px;
  text-decoration: none;
  background: #f0f2f7;
  border: 1px solid #f0f2f7;
  border-radius: 6px;
  outline: 0 !important;
  resize: none;
  transition: .25s;
}

/deep/ .el-textarea__inner:focus{
  background: #fff;
  box-shadow: 0 0 0 2px #289dfb;
  transition: .25s;
}

/deep/ .el-textarea .el-input__count {
  background: initial;
}

.comment-input {
  width: 220px;
}

.comment-input-small {
  width: 180px;
}

.comment-form {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.comment-avatar {
  margin-right: 15px;
}

.form-input {
  width: 230px;
}

.comment-form-wrap {
  /*65 = 50(头像宽度) + 15(头像右外边距)*/
  width: calc(100% - 65px);
}

.comment-form-wrap-small {
  width: calc(95% - 65px);
}

.comment-input-wrap {

}


.emoji-open-button {
  /*布局*/
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 40px;
  width: 40px;
  /*文本*/
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  /*外观*/
  color: #99a9bf;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #e0e2e7;
  outline: #99a9bf none 0;
  /*其他*/
  border-radius: 6px;
  transition: .3s;
  user-select: none;
}

.emoji-open-button:hover {
  color: #475669;
}

.comment-meta {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.comment_nickname {
  /*布局*/
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-left: 20px;
  padding: 0 10px;
  height: 40px;
  /*文本*/
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  /*外观*/
  color: #99a9bf;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #e0e2e7;
  outline: #99a9bf none 0;
  /*其他*/
  border-radius: 6px;
  transition: .3s;
}

.comment_nickname:hover {
  color: #475669;
}

/*配合v-show的组件过渡动画*/
.slide-fade-enter-active {
  transition: all .4s ease;
}

.slide-fade-leave-active {
  transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.cancel-comment {
  position: absolute;
  margin-right: 130px;
  right: 0;
  height: 40px;
  border: none;
  background: radial-gradient(circle farthest-corner at 75% 25%,#30d393 0,#3ac1c2 100%);
  color: #fff;
  padding: 6px 15px;
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 5px rgba(48, 211, 147, 0.5);
}

.submit-comment {
  position: absolute;
  right: 0;
  height: 40px;
  border: none;
  background: #20a0ff;
  background: linear-gradient(90deg, #20a0ff, #20b8ff);
  color: #fff;
  padding: 6px 15px;
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 5px rgb(32 160 255 / 50%);
  box-shadow: 0 3px 5px rgb(32 160 255 / 50%);
}

.emoji-box {
  color: #222;
  overflow: visible;
  background: #fff;
  border: 1px solid #E5E9EF;
  box-shadow: 0 11px 12px 0 rgba(106, 115, 133, 0.3);
  border-radius: 8px;
  width: 340px;
  position: absolute;
  margin-top: 16px;
  z-index: 100;
}

.emoji-box * {
  box-sizing: content-box;
}

.emoji-box .emoji-title {
  font-size: 12px;
  line-height: 16px;
  margin: 13px 15px 0;
  color: #757575;
}

.emoji-box .emoji-wrap {
  margin: 6px 11px 0 11px;
  height: 185px;
  overflow: auto;
  word-break: break-word;
}

.emoji-box .emoji-wrap .emoji-list {
  color: #111;
  border-radius: 4px;
  line-height: 11px;
  /*transition: background 0.1s;*/
  display: inline-block;
  outline: 0;
}

.emoji-box .emoji-wrap .emoji-list:hover {
  background-color: #ddd;
}

.emoji-box .emoji-wrap .emoji-list img {
  /*如果是margin: 4px的话，图片边缘处点击事件无法触发*/
  padding: 4px;
  width: 25px;
  height: 25px;
}

.emoji-box .emoji-tabs {
  position: relative;
  height: 36px;
  overflow: hidden;
  background-color: #f4f4f4;
  border-radius: 0 0 4px 4px;
}

.emoji-box .emoji-tabs .tab-link {
  float: left;
  padding: 7px 18px;
  width: 22px;
  height: 22px;
}

.emoji-box .emoji-tabs .tab-link.on {
  background-color: #fff;
}

.emoji-box .emoji-tabs .tab-link img {
  width: 22px;
}

.emoji-box .emoji-tabs .tab-link:hover {
  background: #e7e7e7;
}

.mask {
  pointer-events: auto;
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, .25);
}


</style>