<template>
  <div>
    <div style="display: flex;align-items: center;margin-bottom: 40px;">
      <svg xmlns="http://www.w3.org/2000/svg" style="width: 30px;" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"></path>
      </svg>
      <span style="font-size: 25px">&nbsp;评论区 | 共{{pageComment.allComment}}条评论（{{pageComment.hiddenComment}}条评论被隐藏）</span>
    </div>
    <!--通过控制pid值响应式加载评论表单-->
    <CommentForm v-if="pid === -1" :blog-id="blogId" :page="page" :pid="pid" :root-id=-1
                 @refreshComments="getPageComment" />
    <div class="comments-list">
      <!--根评论-->
      <div v-for="item in pageComment.list" :key="item.id" class="comment-item" v-if="item.published === true">
        <div class="comment-item-wrap">
          <!--头像-->
          <div class="uer-info-1">
            <el-avatar :size="50" :src="item.avatar" class="comment-avatar" icon="el-icon-user-solid"></el-avatar>
          </div>
          <!--昵称、内容、日期、回复按钮-->
          <div class="uer-info-2">
            <!--接口获取的昵称中包含标点符号为HTML字符实体，使用v-html才能正常展示数据-->
            <div class="user-name" v-html="item.nickname"></div>
            <div class="user-comment">{{ item.content }}</div>
            <div class="user-data">
              <span style="margin-right: 30px">{{ item.createTime | dateFormat("YYYY-MM-DD HH:mm:ss") }}</span>
              <span @click="doComment(item.id)">回复</span>
            </div>
          </div>
        </div>
        <!--根评论的一级子评论-->
        <CommentForm v-if="pid === item.id" :blog-id="blogId" :page="page" :pid="item.id" :root-id="item.id"
                     style="margin-top: 20px;margin-bottom: 0"
                     @refreshComments="getPageComment" @cancelComment="cancelComment"/>
        <!--嵌套子评论集-->
        <div class="subComments-list">
          <div v-for="subItem in item.children" class="subComment-item" v-if="subItem.published === true">
            <div class="subComment-item-wrap">
              <div class="subUer-info-1">
                <el-avatar :size="50" :src="subItem.avatar" class="comment-avatar"
                           icon="el-icon-user-solid"></el-avatar>
              </div>
              <div class="subUer-info-2">
                <div class="user-name" v-html="subItem.nickname"></div>
                <div class="user-comment">
                  <!--当回复对象同为子评论时显示如下内容，是父评论时不显示-->
                  <span v-if="subItem.pnickname !== item.nickname">{{ "回复 " }}</span>
                  <span v-if="subItem.pnickname !== item.nickname" class="atColor" v-html="'@' + subItem.pnickname"></span>
                  <span v-if="subItem.pnickname !== item.nickname">{{ " :" }}</span>
                  <!--子评论内容-->
                  <span>{{ subItem.content }}</span>
                </div>
                <div class="user-data">
                  <span style="margin-right: 30px">{{ subItem.createTime | dateFormat("YYYY-MM-DD HH:mm:ss") }}</span>
                  <span @click="doComment(subItem.id)">回复</span>
                </div>
              </div>
            </div>
            <!--子评论表单，元素间隔有所缩小-->
            <CommentForm v-if="pid === subItem.id" :blog-id="blogId" :is-small-input="true" :is-small-textarea="true" :isSmallCancel = "true"
                         :page="page" :pid="subItem.id" :root-id="subItem.rootId"
                         style="margin-top: 20px;margin-bottom: 0;"
                         @refreshComments="getPageComment" @cancelComment="cancelComment"/>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
        :current-page="pageNum"
        :page-size="5"
        :total="total"
        background
        layout="prev, pager, next"
        style="text-align: center;margin: 40px 0"
        @current-change="changePage">
    </el-pagination>
  </div>
</template>

<script>
import CommentForm from "@/components/comment/CommentForm";
import emojiMapper from "@/assets/emoji/emojiMapper.json";

export default {
  name: "commentList",
  components: {CommentForm},
  data() {
    return {
      page: null,
      pageNum: 1,
      total: null,
      blogId: null,
      pid: -1,
      pageComment: '',
      emojiMapper: null,
    }
  },
  created() {
    this.emojiMapper = emojiMapper
    //判断页面类型
    let type = this.$route.name
    if (type === "blog") {
      this.page = "0"
      this.blogId = this.$route.params.id
    } else if (type === "about") {
      this.page = "1"
    } else if (type === "friends") {
      this.page = "2"
    }
    this.getPageComment()

  },
  mounted() {

  },
  methods: {
    getPageComment() {
      this.$request.get("comment/page-comment", {
        params: {
          page: this.page,
          pageNum: this.pageNum,
          //this.$store.blogInfo的commit有延迟，会获取不到，改为this.$route.params.id
          blogId: this.$route.params.id
        }
      },).then(res => {
        this.pageComment = res.data
        this.total = res.data.total
      }).then(() => {
        //渲染表情包
        if (this.total !== 0) {
          this.emojiConvert()
        }
      })
    },
    changePage(pageNum) {
      this.pageNum = pageNum
      this.getPageComment()
    },
    doComment(pid) {
      this.pid = pid
    },
    cancelComment() {
      this.pid = -1
    },
    emojiConvert() {
      //获取评论内容所在的元素
      let emojiHtml = document.querySelectorAll(".user-comment")
      for (let item in emojiHtml) {
        //将评论内容中被[]标记的字符替换为img图片
        //匹配所有的[]
        emojiHtml[item].innerHTML = emojiHtml[item].innerHTML.replace(/\[.+?]/g, function () {
          //forin遍历，匹配当前[]中的内容，获得标题与分类
          let title = arguments[0].match(/(?<=\[).+?(?=])/)
          let category = arguments[0].match(/(?<=\[).+?(?=_)/)
          //当标题和分类都为正确值时才运行进行src的查找，否则一旦报错循环会立即停止
          if (category != null && emojiMapper[category[0]] != null && emojiMapper[category[0]].find(ele => ele.title === title[0]) != null) {
            //返回替换的img
            if (category[0] === "ac1") {
              //ac娘表情包图片细节较多，使用较大宽高展示，并设置margin-top以抵消表情包顶部的空白空间
              return `<img src="${emojiMapper[category[0]].find(ele => ele.title === title[0]).src}" style="width: 60px;height: 60px;margin-top: -10px;">`
            } else if (category[0] === "weibo") {
              //微博表情包添加padding增大间隔缩小面积
              return `<img src="${emojiMapper[category[0]].find(ele => ele.title === title[0]).src}" style="width: 33px;height: 33px;padding: 2px;vertical-align: bottom;">`
            } else {
              return `<img src="${emojiMapper[category[0]].find(ele => ele.title === title[0]).src}" style="width: 32px;height: 32px;vertical-align: bottom;">`
            }
          } else {
            //异常输入返回空，达到“和谐”的效果
            return ""
          }

        })
      }
    },

  }
}
</script>

<style scoped>
.comments-list {
  word-break: break-all;
  position: relative;
}

.comment-item {
  margin-bottom: 30px;
}

.user-name {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

.comment-avatar {
  margin-right: 15px;
}

.user-comment {
  margin-bottom: 10px;
  padding-right: 35px;
  line-height: 24px;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}

.uer-info-1 {
}

.uer-info-2 {

}

.user-data {
  color: rgb(148, 153, 160);
  font-size: 12px;
}

.subComments-list {
  padding-left: 62px;
}

.subComment-item {
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  border-radius: 5px;
  margin-top: 20px;
  padding: 15px 0 15px 15px;
  /*用于提交评论后新评论插入的过渡动画*/
  animation: subItem 0.8s;
}

.subUer-info-2 {

}

.comment-item-wrap {
  display: flex;
  flex-direction: row;
  /*用于提交评论后新评论插入的过渡动画*/
  animation: comment-item-wrap 0.8s;
}
.subComment-item-wrap {
  display: flex;
  flex-direction: row;
}
.atColor {
  color: #008ac5;
}

.atColor:hover {
  color: #40c5f1;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: rgba(59, 241, 213, 0.8)
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgba(59, 241, 213, 0.8);
  color: #fff
}


</style>